import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

import { useHttpClient } from "../../../shared/hooks/http-hook";

import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";

function CoRepFilter(props) {
  const { isLoading } = useHttpClient();
  const { t } = useTranslation();
  let initialSubDeptArr = [];
  props.coData.sub_departments.map((dept) => {
    dept.map((subdept) => {
      initialSubDeptArr.push(subdept);
    });
  });

  const [activePeriod, setActivePeriod] = useState(props.coData.report_period);
  const [periods, setPeriods] = useState(props.coData.report_period_array);
  const [regionGroups, setRegionGroups] = useState(
    props.allowedFilters.breakdownByRegionGroups
  );
  const [activeRegionGroup, setActiveRegionGroup] = useState();
  const [allowedRegionGroups, setAllowedRegionGroups] = useState();
  const [regions, setRegions] = useState(props.coData.regions);
  const [allowedRegions, setAllowedRegions] = useState();
  const [departments, setDepartments] = useState(props.coData.departments);
  const [allowedDepartments, setAllowedDepartments] = useState();
  const [subDepts, setSubDepts] = useState(initialSubDeptArr);
  const [allowedSubDepts, setAllowedSubDepts] = useState();
  const [allowedFilters, setAllowedFilters] = useState();
  const [activeRegion, setActiveRegion] = useState();
  const [activeFilter, setActiveFilter] = useState();
  const [activeDepartment, setActiveDepartment] = useState();
  const [activeSubDept, setActiveSubDept] = useState();
  const [regionCount, setRegionCount] = useState(8);
  const [departmentsCount, setDepartmentsCount] = useState(8);
  const [subDeptsCount, setSubDeptsCount] = useState(8);
  const [filtersCount, setFiltersCount] = useState(10);
  const [showAllRegions, setShowAllRegions] = useState();
  const [showAllDepts, setShowAllDepts] = useState();
  const [showAllSubDepts, setShowAllSubDepts] = useState();
  const [showAllFilters, setShowAllFilters] = useState();

  const limit = props.threshold;

  let otherFilterArr = ["Male", "Female", "Day shift", "Night shift"];
  let deptArr = [];
  let subDeptFilteredArr = [];
  let subDeptArr = [];
  let regionArr = [];
  let regionGroupArr = [];
  let workTypeArr = [];
  const [filters, setFilters] = useState();

  const resetAllActive = () => {
    setActivePeriod(props.coData.report_period);
    setActiveRegionGroup();
    setActiveDepartment();
    setActiveSubDept();
    setActiveRegion();
    setActiveFilter();
  };

  const resetFilters = (props) => {
    setRegionGroups(props.allowedFilters.breakdownByRegionGroups);
    setRegions(props.coData.regions);
    setDepartments(props.coData.departments);
    setSubDepts(initialSubDeptArr);
    otherFilterArr = [];
    deptArr = [];
    subDeptFilteredArr = [];
    regionArr = [];
    regionGroupArr = [];
    if (props) {
      props.allowedFilters.deptUserCount.map((item, index) => {
        if (item >= limit) {
          deptArr.push(props.coData.departments[index]);
        }
      });
      // if (deptArr.length > 1) {
      setAllowedDepartments(deptArr);
      // } else {
      //   setAllowedDepartments([]);
      //   if (initialSubDeptArr.length > 0) {
      //     setSubDepts(initialSubDeptArr);
      //     setAllowedSubDepts();
      //   }
      // }

      props.allowedFilters.subDeptUserCount.map((dept, index) => {
        let deptArr = [];
        dept.map((subdept, index1) => {
          if (subdept >= limit) {
            deptArr.push(props.coData.sub_departments[index][index1]);
          }
        });
        if (deptArr.length > 1) {
          deptArr.map((dept) => {
            subDeptFilteredArr.push(dept);
          });
        }
      });

      setAllowedSubDepts(subDeptFilteredArr);

      props.allowedFilters.regionUserCount.map((item, index) => {
        if (item >= limit) {
          regionArr.push(props.coData.regions[index]);
        }
      });

      props.allowedFilters.regionUserCount.map((item, index) => {
        if (item >= limit) {
          regionArr.push(props.coData.regions[index]);
        }
      });
      if (activeRegionGroup) {
        regionArr.unshift("All");
      }
      setAllowedRegions(regionArr);

      let rgroups = props.allowedFilters.breakdownByRegionGroups;
      Object.keys(rgroups).map((key, index) => {
        if (rgroups[key].data.total >= limit) {
          regionGroupArr.push(key);
        }
      });
      setAllowedRegionGroups(regionGroupArr);

      if (
        props.allowedFilters.male >= limit &&
        props.allowedFilters.female >= limit
      ) {
        otherFilterArr.push("Male", "Female");
      }
      if (
        props.allowedFilters.day_shift >= limit &&
        props.allowedFilters.night_shift >= limit
      ) {
        otherFilterArr.push("Day shift", "Night shift");
      }
      workTypeArr = [];
      Object.keys(props.allowedFilters.breakdownByWorkType).map(
        (work, index) => {
          if (props.allowedFilters.breakdownByWorkType[work] >= limit) {
            workTypeArr.push(work);
          }
        }
      );
      if (workTypeArr.length > 1) {
        otherFilterArr.push(...workTypeArr);
      }
      setAllowedFilters(otherFilterArr);
    }
  };

  const createOtherFilterArr = (dataObject) => {
    workTypeArr = [];
    if (dataObject.male >= limit && dataObject.female >= limit) {
      otherFilterArr.push("Male", "Female");
    }
    if (dataObject.day_shift >= limit && dataObject.night_shift >= limit) {
      otherFilterArr.push("Day shift", "Night shift");
    }
    Object.keys(dataObject).map((work, index) => {
      if (/[A-Z]/.test(work.charAt(0)) && dataObject[work] >= limit) {
        workTypeArr.push(work);
      }
    });

    if (workTypeArr.length > 1) {
      otherFilterArr.push(...workTypeArr);
    }
    setAllowedFilters(otherFilterArr);
    if (activeFilter && !otherFilterArr.includes(activeFilter)) {
      setActiveFilter();
    }
    return otherFilterArr;
  };

  const createRegionGroupArr = (regionArr) => {
    regionGroupArr = [];
    Object.keys(props.allowedFilters.breakdownByRegionGroups).map(
      (group, index) => {
        props.allowedFilters.breakdownByRegionGroups[group].regions.map(
          (region, index) => {
            if (regionArr.includes(region) && !regionGroupArr.includes(group)) {
              regionGroupArr.push(group);
            }
          }
        );
      }
    );
    setAllowedRegionGroups(regionGroupArr);
  };

  useEffect(() => {
    Object.keys(props.allowedFilters.breakdownByWorkType).map((work, index) => {
      if (props.allowedFilters.breakdownByWorkType[work] >= limit) {
        workTypeArr.push(work);
      }
    });

    if (workTypeArr.length > 1) {
      workTypeArr.forEach((item) => {
        !otherFilterArr.includes(item) && otherFilterArr.push(item);
      });
    }
    setFilters(otherFilterArr);

    if (
      props.filtersArr &&
      props.filtersArr.length > 0 &&
      props.filtersArr[0] === props.coData.report_period
    ) {
      setAllowedRegionGroups(props.filterSnapshot.RegionGroups);
      setRegions(props.filterSnapshot.FilteredRegions);
      setAllowedRegions(props.filterSnapshot.Regions);
      setAllowedDepartments(props.filterSnapshot.Departments);
      setSubDepts(props.filterSnapshot.FilteredSubDepts);
      setAllowedSubDepts(props.filterSnapshot.SubDepartments);
      setAllowedFilters(props.filterSnapshot.Filters);
      setActiveRegionGroup(props.filtersArr[1]);
      setActiveRegion(props.filtersArr[2]);
      if (props.filtersArr[3] >= 0) {
        setActiveDepartment(props.coData.departments[props.filtersArr[3]]);
      }
      if (props.filtersArr[4] >= 0 || props.filtersArr[4] === "All") {
        if (props.filtersArr[4] === "All") {
          setActiveSubDept("All");
        } else {
          const subdeptsToFilter =
            props?.filterSnapshot?.FilteredSubDepts ?? [];
          const validSnapshotSubdepts = subdeptsToFilter.filter(
            (subDept) => subDept !== "All"
          );
          if (validSnapshotSubdepts.length > props.filtersArr[4]) {
            setActiveSubDept(validSnapshotSubdepts[props.filtersArr[4]]);
          }
        }
      }
      setActiveFilter(props.filtersArr[5]);
    } else {
      resetFilters(props);
      if (activeFilter || activeDepartment || activeRegion || activeSubDept) {
        if (
          activeRegionGroup &&
          !allowedRegionGroups.includes(activeRegionGroup)
        ) {
          setActiveRegionGroup();
          setActiveRegion();
        } else if (
          activeRegionGroup &&
          allowedRegionGroups.includes(activeRegionGroup)
        ) {
          let possibleRegions;
          if (regionGroups[activeRegionGroup].regions.length > 1) {
            possibleRegions = [
              "All",
              ...regionGroups[activeRegionGroup].regions,
            ];
          } else {
            possibleRegions = [...regionGroups[activeRegionGroup].regions];
          }
          setRegions(possibleRegions);
          if (!possibleRegions.includes(activeRegion)) {
            setActiveRegion();
            setActiveRegionGroup();
          }
        }
        if (
          (allowedRegions && !allowedRegions.includes(activeRegion)) ||
          activeRegion === undefined ||
          !allowedRegions
        ) {
          setActiveRegion();
          setActiveRegionGroup();
        }
        if (
          (allowedDepartments &&
            !allowedDepartments.includes(activeDepartment)) ||
          !allowedDepartments
        ) {
          setActiveDepartment();
        }
        if (
          !allowedSubDepts ||
          (allowedSubDepts && !allowedSubDepts.includes(activeSubDept))
        ) {
          setActiveSubDept();
        }
        if (
          (allowedFilters && !allowedFilters.includes(activeFilter)) ||
          !allowedFilters
        ) {
          setActiveFilter();
        }

        if (activeRegion && activeDepartment && activeFilter) {
          let selectedRegionAndDept;
          otherFilterArr = [];
          workTypeArr = [];
          let deptIndex = departments.indexOf(activeDepartment);
          let regionIndex = props.coData.regions.indexOf(activeRegion);
          if (activeRegion === "All" && deptIndex > -1 && regionIndex > -1) {
            selectedRegionAndDept =
              props.allowedFilters.breakdownByRegionGroups[activeRegionGroup]
                .data;
          } else if (
            activeRegion !== "All" &&
            deptIndex > -1 &&
            regionIndex > -1
          ) {
            selectedRegionAndDept =
              props.allowedFilters.breakdownByRegion[regionIndex][deptIndex];
          }
          if (selectedRegionAndDept && selectedRegionAndDept.total < limit) {
            setActiveDepartment();
            createOtherFilterArr(selectedRegionAndDept);
          }
          if (
            initialSubDeptArr &&
            initialSubDeptArr.length > 0 &&
            activeSubDept &&
            activeSubDept !== "All"
          ) {
            let subDeptIndex =
              props.coData.sub_departments[deptIndex].indexOf(activeSubDept) -
              1;
            let selectedRegionDeptSubDept =
              props.allowedFilters.breakdownByRegion[regionIndex][deptIndex][
                subDeptIndex
              ];
            if (
              selectedRegionDeptSubDept &&
              selectedRegionDeptSubDept.total < limit
            ) {
              setActiveSubDept();
              createOtherFilterArr(selectedRegionDeptSubDept);
            }
          }

          if (!otherFilterArr.includes(activeFilter)) {
            setActiveFilter();
          }
        } else if (activeRegion && activeDepartment && !activeFilter) {
          let selectedRegionAndDept;
          otherFilterArr = [];
          workTypeArr = [];
          let deptIndex = departments.indexOf(activeDepartment);
          let regionIndex = props.coData.regions.indexOf(activeRegion);
          if (activeRegion === "All" && deptIndex > -1 && regionIndex > -1) {
            selectedRegionAndDept =
              props.allowedFilters.breakdownByRegionGroups[activeRegionGroup]
                .data;
          } else if (
            activeRegion !== "All" &&
            deptIndex > -1 &&
            regionIndex > -1
          ) {
            selectedRegionAndDept =
              props.allowedFilters.breakdownByRegion[regionIndex][deptIndex];
          }
          if (selectedRegionAndDept && selectedRegionAndDept.total < limit) {
            setActiveDepartment();
            createOtherFilterArr(selectedRegionAndDept);
          }
          if (
            initialSubDeptArr &&
            initialSubDeptArr.length > 0 &&
            activeSubDept &&
            activeSubDept !== "All"
          ) {
            let subDeptIndex =
              props.coData.sub_departments[deptIndex].indexOf(activeSubDept);
            let selectedRegionDeptSubDept =
              props.allowedFilters.breakdownByRegion[regionIndex][deptIndex][
                subDeptIndex
              ];
            if (
              selectedRegionDeptSubDept &&
              selectedRegionDeptSubDept.total < limit
            ) {
              setActiveSubDept();
              createOtherFilterArr(selectedRegionDeptSubDept);
            }
          }
        } else if (activeRegion && !activeDepartment && activeFilter) {
          let selectedRegion;
          otherFilterArr = [];
          workTypeArr = [];
          deptArr = [];
          let regionIndex = props.coData.regions.indexOf(activeRegion);
          if (activeRegion === "All" && regionIndex > -1) {
            selectedRegion =
              props.allowedFilters.breakdownByRegionGroups[activeRegionGroup]
                .data;
          } else if (activeRegion !== "All" && regionIndex > -1) {
            selectedRegion =
              props.allowedFilters.breakdownByRegion[regionIndex];
          }
          if (selectedRegion && selectedRegion.total < limit) {
            createOtherFilterArr(selectedRegion);
            setActiveRegion();
          } else if (selectedRegion && selectedRegion.total >= limit) {
            Object.keys(selectedRegion).map((key, index) => {
              if (!isNaN(key) && selectedRegion[key].total >= limit) {
                deptArr.push(props.coData.departments[key]);
              }
            });
            // if (deptArr.length > 1) {
            setAllowedDepartments(deptArr);
            // } else {
            //   setAllowedDepartments([]);
            //   if (subDepts.length > 0) {
            //     setSubDepts(initialSubDeptArr);
            //     setAllowedSubDepts();
            //   }
            // }
            createOtherFilterArr(selectedRegion);
          }
          if (!otherFilterArr.includes(activeFilter)) {
            setActiveFilter();
          }
        } else if (activeRegion && !activeDepartment && !activeFilter) {
          let selectedRegion;
          otherFilterArr = [];
          workTypeArr = [];
          deptArr = [];
          let regionIndex = props.coData.regions.indexOf(activeRegion);
          if (activeRegion === "All" && regionIndex > -1) {
            selectedRegion =
              props.allowedFilters.breakdownByRegionGroups[activeRegionGroup]
                .data;
          } else if (activeRegion !== "All" && regionIndex > -1) {
            selectedRegion =
              props.allowedFilters.breakdownByRegion[regionIndex];
          }
          if (selectedRegion && selectedRegion.total < limit) {
            setActiveRegion();
            setActiveRegionGroup();
            createOtherFilterArr(selectedRegion);
          } else if (selectedRegion && selectedRegion.total >= limit) {
            Object.keys(selectedRegion).map((key, index) => {
              if (!isNaN(key) && selectedRegion[key].total >= limit) {
                deptArr.push(props.coData.departments[key]);
              }
            });
            // if (deptArr.length > 1) {
            setAllowedDepartments(deptArr);
            // } else {
            //   setAllowedDepartments([]);
            //   if (subDepts.length > 0) {
            //     setSubDepts(initialSubDeptArr);
            //     setAllowedSubDepts();
            //   }
            // }
            createOtherFilterArr(selectedRegion);
          }
        } else if (!activeRegion && activeDepartment && !activeFilter) {
          regionArr = [];
          otherFilterArr = [];
          workTypeArr = [];
          let deptIndex = departments.indexOf(activeDepartment);
          if (
            deptIndex > -1 &&
            props.allowedFilters.deptUserCount[deptIndex] < limit
          ) {
            setActiveDepartment();
          }
          props.allowedFilters.breakdownByRegion.map((region, index) => {
            if (region[deptIndex].total >= limit) {
              regionArr.push(props.coData.regions[index]);
            }
          });
          let selectedDept = props.allowedFilters.breakdownByDept[deptIndex];
          setAllowedRegions(regionArr);
          createRegionGroupArr(regionArr);
          createOtherFilterArr(selectedDept);
        } else if (!activeRegion && !activeDepartment && activeFilter) {
          otherFilterArr = [];
          workTypeArr = [];
          if (
            (activeFilter === "Male" || activeFilter === "Female") &&
            (props.allowedFilters.male < limit ||
              props.allowedFilters.female < limit)
          ) {
            setActiveFilter();
          } else if (
            (activeFilter === "Day shift" || activeFilter === "Night shift") &&
            (props.allowedFilters.day_shift < limit ||
              props.allowedFilters.night_shift < limit)
          ) {
            setActiveFilter();
          } else {
            Object.keys(props.allowedFilters.breakdownByWorkType).map(
              (work, index) => {
                if (
                  activeFilter === work &&
                  props.allowedFilters.breakdownByWorkType[work] >= limit
                ) {
                  workTypeArr.push(work);
                }
              }
            );
            if (!workTypeArr.includes(activeFilter)) {
              setActiveFilter();
            }
          }

          regionArr = [];

          props.allowedFilters.breakdownByRegion.map((region, index) => {
            if (
              (activeFilter === "Male" || activeFilter === "Female") &&
              region.male >= limit &&
              region.female >= limit
            ) {
              regionArr.push(props.coData.regions[index]);
            } else if (
              (activeFilter === "Day shift" ||
                activeFilter === "Night shift") &&
              region.day_shift >= limit &&
              region.night_shift >= limit
            ) {
              regionArr.push(props.coData.regions[index]);
            } else {
              Object.keys(region).map((item, indexx) => {
                if (activeFilter === item && region[item] >= limit) {
                  regionArr.push(props.coData.regions[index]);
                }
              });
            }
          });
          deptArr = [];
          props.allowedFilters.breakdownByDept.map((dept, index) => {
            if (
              (activeFilter === "Male" || activeFilter === "Female") &&
              dept.male >= limit &&
              dept.female >= limit
            ) {
              deptArr.push(departments[index]);
            } else if (
              (activeFilter === "Day shift" ||
                activeFilter === "Night shift") &&
              dept.day_shift >= limit &&
              dept.night_shift >= limit
            ) {
              deptArr.push(departments[index]);
            } else {
              Object.keys(dept).map((item, indexx) => {
                if (activeFilter === item && dept[item] >= limit) {
                  deptArr.push(departments[index]);
                }
              });
            }
          });
          // if (deptArr.length > 1) {
          setAllowedDepartments(deptArr);
          // } else {
          //   setAllowedDepartments([]);
          //   if (subDepts.length > 0) {
          //     setSubDepts(initialSubDeptArr);
          //     setAllowedSubDepts();
          //   }
          // }
          setAllowedRegions(regionArr);
          createRegionGroupArr(regionArr);
        }
      }
    }
  }, [props]);

  const selectRegionGroup = (e) => {
    e.preventDefault();
    let newRegionGroup;
    let arr = [];
    if (e.currentTarget.innerText !== activeRegionGroup) {
      newRegionGroup = e.currentTarget.innerText;
      let possibleRegions = [];
      if (regionGroups[e.currentTarget.innerText].regions.length > 1) {
        possibleRegions = [
          "All",
          ...regionGroups[e.currentTarget.innerText].regions,
        ];
      } else {
        possibleRegions = regionGroups[e.currentTarget.innerText].regions;
      }
      setRegions(possibleRegions);

      possibleRegions.map((region, index) => {
        if (region !== "All") {
          let regionIndex = props.coData.regions.indexOf(region);
          if (
            props.allowedFilters.breakdownByRegion[regionIndex].total >= limit
          ) {
            arr.push(region);
          }
        }
      });
      if (possibleRegions.includes("All")) {
        arr = ["All", ...arr];
      }
      setAllowedRegions(arr);
      setActiveRegion(arr[0]);
    } else {
      newRegionGroup = "";
      handleClearAll();
    }

    setActiveRegionGroup(newRegionGroup);
    if (newRegionGroup !== "") {
      processNewRegion(arr[0], newRegionGroup);
    }
  };

  const handleRegionClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let newRegion;
    if (e.currentTarget.id !== activeRegion) {
      newRegion = e.currentTarget.id;
    } else if (e.currentTarget.id === activeRegion) {
      newRegion = "";
      handleClearAll();
    }
    setActiveRegion(newRegion);
    if (newRegion !== "") {
      processNewRegion(newRegion);
    }
  };

  const processNewRegion = (newRegion, newRegionGroup) => {
    let regionIndex = props.coData.regions.indexOf(newRegion);
    let selectedRegion;
    if (newRegion === "All" && newRegionGroup) {
      selectedRegion =
        props.allowedFilters.breakdownByRegionGroups[newRegionGroup].data;
    } else if (newRegion === "All" && activeRegionGroup && !newRegionGroup) {
      selectedRegion =
        props.allowedFilters.breakdownByRegionGroups[activeRegionGroup].data;
    } else {
      selectedRegion = props.allowedFilters.breakdownByRegion[regionIndex];
    }
    if (newRegionGroup || activeRegionGroup) {
    } else {
      setAllowedRegionGroups();
    }
    deptArr = [];
    otherFilterArr = [];
    subDeptArr = [];
    workTypeArr = [];
    Object.keys(selectedRegion).map((key, index) => {
      if (!isNaN(key) && selectedRegion[key].total >= limit) {
        deptArr.push(props.coData.departments[key]);
        if (subDepts.length > 0) {
          Object.keys(selectedRegion[key]).map((keyx, indexx) => {
            if (!isNaN(keyx) && selectedRegion[key][keyx].total >= limit) {
              subDeptArr.push(props.coData.sub_departments[index][indexx]);
            }
          });
        }
      }
    });

    // if (deptArr.length > 1) {
    setAllowedDepartments(deptArr);
    // } else {
    //   setAllowedDepartments([]);
    //   if (subDepts && subDepts.length > 0) {
    //     setSubDepts(initialSubDeptArr);
    //     setAllowedSubDepts();
    //   }
    // }

    if (activeDepartment && !deptArr.includes(activeDepartment)) {
      setActiveDepartment();
    }

    if (subDeptArr.length > 1) {
      subDeptArr.unshift("All");
    } else {
      subDeptArr = ["All"];
    }
    setAllowedSubDepts(subDeptArr);

    if (activeSubDept && !subDeptArr.includes(activeSubDept)) {
      setActiveSubDept();
    }

    createOtherFilterArr(selectedRegion);
  };

  const handleDepartmentClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let newDept;
    if (e.currentTarget.id !== activeDepartment) {
      newDept = e.currentTarget.id;
      if (activeSubDept && activeSubDept !== "All") setActiveSubDept();
    } else if (e.currentTarget.id === activeDepartment) {
      newDept = "";
      handleClearAll();
    }
    let deptIndex = departments.indexOf(newDept);

    setActiveDepartment(newDept);
    if (newDept !== "") {
      processNewDepartment(deptIndex);
    }
    if (!activeRegionGroup) {
      setAllowedRegionGroups();
    }
    if (!activeRegion) setAllowedRegions();
  };

  const processNewDepartment = (deptIndex) => {
    subDeptArr = [];
    if (subDepts && subDepts.length > 0) {
      subDeptArr = [...props.coData.sub_departments[deptIndex]];
      if (subDeptArr.length > 1) {
        subDeptArr.unshift("All");
      }
      setSubDepts(subDeptArr);

      if (!activeFilter && !activeRegion) {
        let allowedSubDeptArr = [];
        props.allowedFilters.subDeptUserCount[deptIndex].map(
          (subdepts, index) => {
            if (subdepts >= limit) {
              allowedSubDeptArr.push(
                props.coData.sub_departments[deptIndex][index]
              );
            }
          }
        );
        if (allowedSubDeptArr.length > 1) {
          allowedSubDeptArr.unshift("All");
        } else {
          allowedSubDeptArr = ["All"];
        }
        setAllowedSubDepts(allowedSubDeptArr);
        if (!activeSubDept || !allowedSubDeptArr.includes(activeSubDept)) {
          setActiveSubDept(allowedSubDeptArr[0]);
        }
      }
    }
    if (activeRegion) {
      regionArr = [];
      otherFilterArr = [];
      subDeptArr = [];
      workTypeArr = [];
      if (activeRegion !== "All") {
        props.allowedFilters.breakdownByRegion.map((region, index) => {
          if (region[deptIndex].total >= limit) {
            regionArr.push(props.coData.regions[index]);
          }
        });
        if (regionArr.length > 1 && activeRegionGroup) {
          regionArr.unshift("All");
        }
        setAllowedRegions(regionArr);
        createRegionGroupArr(regionArr);
      }
      if (
        (!regions.includes(activeRegion) && activeRegion !== "All") ||
        (activeRegion === "All" &&
          activeRegionGroup &&
          props.allowedFilters.breakdownByRegionGroups[activeRegionGroup].data[
            deptIndex
          ].total < limit)
      ) {
        setActiveRegion();
        setActiveRegionGroup();
        let selectedDept = props.allowedFilters.breakdownByDept[deptIndex];
        createOtherFilterArr(selectedDept);
      } else {
        let selectedRegionAndDept;
        if (activeRegion === "All") {
          selectedRegionAndDept =
            props.allowedFilters.breakdownByRegionGroups[activeRegionGroup]
              .data[deptIndex];
        } else {
          let regionIndex = props.coData.regions.indexOf(activeRegion);
          selectedRegionAndDept =
            props.allowedFilters.breakdownByRegion[regionIndex][deptIndex];
        }
        createOtherFilterArr(selectedRegionAndDept);
        if (subDepts.length > 0) {
          subDeptArr = [];
          Object.keys(selectedRegionAndDept).map((dept, indexx) => {
            if (!isNaN(dept) && selectedRegionAndDept[dept].total >= limit) {
              subDeptArr.push(props.coData.sub_departments[deptIndex][indexx]);
            }
          });
        }
        if (subDeptArr.length > 1) {
          subDeptArr.unshift("All");
        } else {
          subDeptArr = ["All"];
        }
        setAllowedSubDepts(subDeptArr);
        if (!activeSubDept || !subDeptArr.includes(activeSubDept)) {
          setActiveSubDept(subDeptArr[0]);
        }
      }
    } else if (!activeRegion && !activeFilter) {
      regionArr = [];
      otherFilterArr = [];
      workTypeArr = [];
      props.allowedFilters.breakdownByRegion.map((region, index) => {
        if (region[deptIndex].total >= limit) {
          regionArr.push(props.coData.regions[index]);
        }
      });
      if (!regionArr.includes(activeRegion)) {
        setActiveRegion();
      }
      setAllowedRegions(regionArr);
      createRegionGroupArr(regionArr);
      let selectedDept = props.allowedFilters.breakdownByDept[deptIndex];
      createOtherFilterArr(selectedDept);
      if (!otherFilterArr.includes(activeFilter)) {
        setActiveFilter();
      }
    }
    if (activeRegion && activeFilter && activeSubDept) {
      if (!allowedFilters.includes(activeFilter)) {
        setActiveFilter();
      }
      if (!allowedRegions.includes(activeRegion)) {
        setActiveRegion();
      }
      if (!activeSubDept || !allowedSubDepts.includes(activeSubDept)) {
        setActiveSubDept("All");
      }
    }
  };

  const handleSubDepartmentClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let newSubDept;
    if (e.currentTarget.id !== activeSubDept) {
      newSubDept = e.currentTarget.id;
    } else if (e.currentTarget.id === activeSubDept) {
      newSubDept = "";
      handleClearAll();
    }
    let deptIndex;
    let subDeptIndex;
    if (!activeDepartment) {
      props.coData.sub_departments.map((dept, indexD) => {
        dept.map((subdept, indexS) => {
          if (subdept === newSubDept) {
            deptIndex = indexD;
            subDeptIndex = indexS;
          }
        });
      });
      setActiveDepartment(departments[deptIndex]);
      processNewDepartment(deptIndex);
    } else {
      deptIndex = departments.indexOf(activeDepartment);
      subDeptIndex =
        props.coData.sub_departments[deptIndex].indexOf(newSubDept);
    }
    setActiveSubDept(newSubDept);
    if (newSubDept !== "" && newSubDept !== "All") {
      let data;
      if (!activeRegion) {
        data = props.allowedFilters.breakdownBySubDept[deptIndex][subDeptIndex];
      } else if (activeRegion && activeRegion !== "All") {
        let regionIndex = props.coData.regions.indexOf(activeRegion);
        data =
          props.allowedFilters.breakdownByRegion[regionIndex][deptIndex][
            subDeptIndex
          ];
      } else if (activeRegion && activeRegion === "All" && activeRegionGroup) {
        data =
          props.allowedFilters.breakdownByRegionGroup[activeRegionGroup].data[
            deptIndex
          ][subDeptIndex];
      }
      otherFilterArr = [];
      otherFilterArr = createOtherFilterArr(data);
    } else if (newSubDept === "All") {
      processNewDepartment(deptIndex);
    }
    if (!activeRegionGroup) {
      setAllowedRegionGroups();
    }
    if (!activeRegion) setAllowedRegions();
  };

  const handleFilterClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let newFilt;
    if (e.currentTarget.id !== activeFilter) {
      newFilt = e.currentTarget.id;
    } else if (e.currentTarget.id === activeFilter) {
      newFilt = "";
      handleClearAll();
    }
    setActiveFilter(newFilt);
    if (!activeRegionGroup) {
      setAllowedRegionGroups();
    }
    if (!activeRegion) setAllowedRegions();
    if (!activeDepartment) setAllowedDepartments();
    if (!activeSubDept) setAllowedSubDepts();
  };

  const filterResults = () => {
    let department;
    if (activeDepartment) {
      department = departments.indexOf(activeDepartment);
    }
    let subDept;
    if (activeSubDept && activeSubDept !== "All") {
      const validSubDept = subDepts.filter((subDept) => subDept !== "All");
      subDept = validSubDept.indexOf(activeSubDept);
    } else if (activeSubDept === "All") {
      subDept = "All";
    }
    let filtersArr = [
      activePeriod,
      activeRegionGroup,
      activeRegion,
      department,
      subDept,
      activeFilter,
    ];
    let filterObj = {
      RegionGroups: allowedRegionGroups,
      FilteredRegions: regions,
      Regions: allowedRegions,
      Departments: allowedDepartments,
      FilteredSubDepts: subDepts,
      SubDepartments: allowedSubDepts,
      Filters: allowedFilters,
    };
    props.filterSelectHandler(filtersArr, filterObj);
  };

  const setNewPeriod = (e) => {
    if (e.currentTarget.innerText !== activePeriod) {
      setActivePeriod(e.currentTarget.innerText);
      props.getDataForSetPeriod(e.currentTarget.innerText);
    }
  };

  const closeModal = (e) => {
    e.stopPropagation();
    if (e.target.className === "filter-overlay") {
      props.toggleFilterOpen();
    }
  };

  const handleClearAll = () => {
    resetFilters(props);
    resetAllActive();
    props.clearFilterArr();
  };

  const handleRegionSeeMore = () => {
    let newcount = regions.length + 1;
    setRegionCount(newcount);
    setShowAllRegions("expanded");
  };

  const handleDepartmentsSeeMore = () => {
    let newcount = departments.length + 1;
    setDepartmentsCount(newcount);
    setShowAllDepts("expanded");
  };

  const handleSubDeptsSeeMore = () => {
    let newcount = subDepts.length + 1;
    setSubDeptsCount(newcount);
    setShowAllSubDepts("expanded");
  };

  const handleFiltersSeeMore = () => {
    let newcount = filters.length + 1;
    setFiltersCount(newcount);
    setShowAllFilters("expanded");
  };

  return (
    <div className="filter-overlay" onClick={closeModal}>
      <div id="filter-options">
        <div className="filters-title heading">
          {t("CR001")}{" "}
          <FontAwesomeIcon
            onClick={props.toggleFilterOpen}
            icon={faTimes}
            className="icon"
          />
        </div>
        <div className="filters-title refined">
          {t("CR002")}{" "}
          <div className="refined-container">
            {activePeriod.length > 2 ? (
              <p>{activePeriod}</p>
            ) : (
              <p>
                {t("CR003")}
                {activePeriod}
              </p>
            )}
            {activeRegionGroup && (
              <p>{activeRegionGroup + " - " + activeRegion}</p>
            )}
            {!activeRegionGroup && activeRegion && <p>{activeRegion}</p>}
            {activeDepartment && activeSubDept && (
              <p>
                {activeDepartment.replace(/&amp;/gi, "&") +
                  " - " +
                  activeSubDept.replace(/&amp;/gi, "&")}
              </p>
            )}
            {activeDepartment && !activeSubDept && (
              <p>{activeDepartment.replace(/&amp;/gi, "&")}</p>
            )}
            {!activeDepartment && activeSubDept && (
              <p>{activeSubDept.replace(/&amp;/gi, "&")}</p>
            )}
            {activeFilter && <p>{activeFilter}</p>}
          </div>
          <div className="clear-filters" onClick={handleClearAll}>
            {t("CR004")}
          </div>
        </div>
        {isLoading && <LoadingSpinner />}
        <div id="scrollable">
          {periods && (
            <React.Fragment>
              {periods && periods.length > 0 && (
                <div className="filter-container">
                  <div className="filter_group_name">{t("CR005")}</div>
                  <div className="options-container">
                    {periods.map((period) => {
                      let selected;
                      period === activePeriod
                        ? (selected = "selected")
                        : (selected = "");
                      return (
                        <button
                          onClick={setNewPeriod}
                          key={period}
                          className={selected}
                        >
                          {period}
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
          {regions && regions.length > 0 && (
            <div className="filter-container">
              <div className="filter_group_name">{t("CR006")}</div>
              {regionGroups && Object.keys(regionGroups).length > 1 && (
                <React.Fragment>
                  <div className="filter_italics">{t("CR007")}</div>
                  <div className="options-container region-groups">
                    {Object.keys(regionGroups).map((key, index) => {
                      let selected;
                      key === activeRegionGroup
                        ? (selected = "selected")
                        : (selected = "");
                      if (
                        allowedRegionGroups &&
                        allowedRegionGroups.includes(key)
                      ) {
                        return (
                          <button
                            className={selected}
                            key={key}
                            onClick={selectRegionGroup}
                          >
                            {key}
                          </button>
                        );
                      } else {
                        return (
                          <div className="tooltip_container button" key={key}>
                            <div className="tooltip">{t("CR008")}</div>
                            <button
                              className={"disabled " + selected}
                              disabled
                              onClick={selectRegionGroup}
                            >
                              {key}
                            </button>
                          </div>
                        );
                      }
                    })}
                  </div>
                </React.Fragment>
              )}
              <div className="filter_italics">{t("CR009")}</div>
              <div className={"options-container columns " + showAllRegions}>
                {regions.map((item, index) => {
                  let checked;
                  item === activeRegion
                    ? (checked = "checked")
                    : (checked = "");
                  if (
                    allowedRegions &&
                    allowedRegions.includes(item) &&
                    index < regionCount
                  ) {
                    return (
                      <div
                        className="filter-option overlay"
                        id={item}
                        key={"region" + index}
                        onClick={handleRegionClick}
                      >
                        {checked ? (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="icon"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faCircle} className="icon" />
                        )}{" "}
                        <div className="text">{item}</div>
                      </div>
                    );
                  } else if (index < regionCount) {
                    return (
                      <div className="tooltip_container" key={"region" + index}>
                        <div className="tooltip">{t("CR010")}</div>
                        <div
                          className="inactive filter-option overlay"
                          id={index}
                        >
                          {checked ? (
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="icon"
                            />
                          ) : (
                            <FontAwesomeIcon icon={faCircle} className="icon" />
                          )}{" "}
                          <div className="text">{item}</div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              {regions && regions.length > regionCount && (
                <div className="show-more" onClick={handleRegionSeeMore}>
                  {t("CR011")}
                </div>
              )}
            </div>
          )}
          {departments && departments.length > 1 && (
            <div className="filter-container">
              <div className="filter_group_name">{t("CR012")}</div>
              <div className={"options-container columns " + showAllDepts}>
                {departments.map((item, index) => {
                  let checked;
                  item === activeDepartment
                    ? (checked = "checked")
                    : (checked = "");
                  if (
                    allowedDepartments &&
                    allowedDepartments.includes(item) &&
                    index < departmentsCount
                  ) {
                    return (
                      <div
                        className="filter-option overlay"
                        id={item}
                        key={"dept" + index}
                        onClick={handleDepartmentClick}
                      >
                        {checked ? (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="icon"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faCircle} className="icon" />
                        )}{" "}
                        <div className="text">
                          {item.replace(/&amp;/gi, "&")}
                        </div>
                      </div>
                    );
                  } else if (index < departmentsCount) {
                    return (
                      <div className="tooltip_container" key={"dept" + index}>
                        <div className="tooltip">Not available</div>
                        <div
                          className="inactive filter-option overlay"
                          id={index}
                        >
                          {checked ? (
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="icon"
                            />
                          ) : (
                            <FontAwesomeIcon icon={faCircle} className="icon" />
                          )}{" "}
                          <div className="text">
                            {item.replace(/&amp;/gi, "&")}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              {departments && departments.length > departmentsCount && (
                <div className="show-more" onClick={handleDepartmentsSeeMore}>
                  {t("CR011")}
                </div>
              )}

              {subDepts && subDepts.length > 1 && (
                <React.Fragment>
                  <div className="filter_italics">{t("CR013")}</div>

                  <div
                    className={"options-container columns " + showAllSubDepts}
                  >
                    {subDepts.map((item, index) => {
                      let checked;
                      item === activeSubDept
                        ? (checked = "checked")
                        : (checked = "");
                      if (
                        allowedSubDepts &&
                        allowedSubDepts.includes(item) &&
                        index < subDeptsCount
                      ) {
                        return (
                          <div
                            className="filter-option overlay"
                            id={item}
                            key={"subdept" + index}
                            onClick={handleSubDepartmentClick}
                          >
                            {checked ? (
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="icon"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faCircle}
                                className="icon"
                              />
                            )}{" "}
                            <div className="text">
                              {item.replace(/&amp;/gi, "&")}
                            </div>
                          </div>
                        );
                      } else if (index < subDeptsCount) {
                        return (
                          <div
                            className="tooltip_container"
                            key={"subdept" + index}
                          >
                            <div className="tooltip">{t("CR008")}</div>
                            <div
                              className="inactive filter-option overlay"
                              id={index}
                            >
                              {checked ? (
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  className="icon"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  className="icon"
                                />
                              )}{" "}
                              <div className="text">
                                {item.replace(/&amp;/gi, "&")}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  {subDepts && subDepts.length > subDeptsCount && (
                    <div className="show-more" onClick={handleSubDeptsSeeMore}>
                      {t("CR011")}
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          )}

          <div className="filter-container filters">
            <div className="filter_group_name">{t("CR014")}</div>
            <div className="options-container columns filters">
              {filters &&
                filters.map((item, index) => {
                  let checked;
                  item === activeFilter
                    ? (checked = "checked")
                    : (checked = "");

                  if (
                    allowedFilters &&
                    allowedFilters.includes(item) &&
                    index < filtersCount &&
                    index < 4
                  ) {
                    return (
                      <div
                        className="filter-option overlay"
                        id={item}
                        key={"dept" + index}
                        onClick={handleFilterClick}
                      >
                        {checked ? (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="icon"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faCircle} className="icon" />
                        )}{" "}
                        <div className="text">
                          {item.replace(/&amp;/gi, "&")}
                        </div>
                      </div>
                    );
                  } else if (index < filtersCount && index < 4) {
                    return (
                      <div className="tooltip_container" key={"dept" + index}>
                        <div className="tooltip">{t("CR010")}</div>
                        <div
                          className="inactive filter-option overlay"
                          id={index}
                        >
                          {checked ? (
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="icon"
                            />
                          ) : (
                            <FontAwesomeIcon icon={faCircle} className="icon" />
                          )}{" "}
                          <div className="text">
                            {item.replace(/&amp;/gi, "&")}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
            {filters && filters.length > 4 && (
              <div className="filter_italics">{t("CR015")}</div>
            )}
            <div
              className={"options-container columns filters " + showAllFilters}
            >
              {filters &&
                filters.map((item, index) => {
                  let checked;
                  item === activeFilter
                    ? (checked = "checked")
                    : (checked = "");

                  if (
                    allowedFilters &&
                    allowedFilters.includes(item) &&
                    index < filtersCount &&
                    index > 3
                  ) {
                    return (
                      <div
                        className="filter-option overlay"
                        id={item}
                        key={"dept" + index}
                        onClick={handleFilterClick}
                      >
                        {checked ? (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="icon"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faCircle} className="icon" />
                        )}{" "}
                        <div className="text">
                          {item.replace(/&amp;/gi, "&")}
                        </div>
                      </div>
                    );
                  } else if (index < filtersCount && index > 3) {
                    return (
                      <div className="tooltip_container" key={"dept" + index}>
                        <div className="tooltip">{t("CR008")}</div>
                        <div
                          className="inactive filter-option overlay"
                          id={index}
                        >
                          {checked ? (
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="icon"
                            />
                          ) : (
                            <FontAwesomeIcon icon={faCircle} className="icon" />
                          )}{" "}
                          <div className="text">
                            {item.replace(/&amp;/gi, "&")}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
            {filters && filters.length > filtersCount && (
              <div className="show-more" onClick={handleFiltersSeeMore}>
                {t("CR011")}
              </div>
            )}
          </div>
        </div>
        <div className="bottom-container">
          <div className="clear-filters" onClick={handleClearAll}>
            {t("CR004")}
          </div>
          <button onClick={filterResults}>{t("CR016")}</button>
        </div>
      </div>
    </div>
  );
}
export default CoRepFilter;
