import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import InviteLeftMenu from "./children/InviteLeftMenu";
import ManageFnFRightMenu from "./children/ManageFnFRightMenu";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useTranslation } from "react-i18next";

const Invite = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [userFnF, setUserFnF] = useState();
  const [activeProfile, setActiveProfile] = useState();
  const [showRightMenu, setShowRightMenu] = useState();
  const [showLeftMenu, setShowLeftMenu] = useState();

  useEffect(() => {
    if (props.hide) {
      setShowRightMenu(false);
      setShowLeftMenu(true);
    } else {
      setShowRightMenu(true);
      setShowLeftMenu(true);
    }
  }, [props.hide]);

  useEffect(() => {
    //F&F and Hub users should not be able to access this page
    if (auth.isHub || auth.access === "2") {
      navigate("/for-you");
    }
  }, [auth]);

  const getProfile = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/profile/friendsAndFamily`
      );
      if (responseData.fandf) {
        setUserFnF(responseData.fandf);
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (!userFnF && auth.userId) {
      getProfile();
    }
  }, [auth.userId, userFnF]);

  useEffect(() => {
    if (!activeProfile && userFnF?.length === 0) {
      setActiveProfile(() => ({
        id: 0,
        action: "invite",
        user: null,
      }));
    } else if (!activeProfile && userFnF?.length > 0) {
      setActiveProfile(() => ({
        id: 0,
        action: "manage",
        user: userFnF[0],
      }));
    } else if (activeProfile && userFnF?.[activeProfile.id]?.name) {
      setActiveProfile((prevState) => ({
        ...prevState,
        action: "manage",
        user: userFnF[activeProfile.id],
      }));
    } else if (activeProfile && !userFnF?.[activeProfile.id]?.name) {
      setActiveProfile((prevState) => ({
        ...prevState,
        action: "invite",
        user: null,
      }));
    }
  }, [userFnF]);

  const handleActiveProfile = (index) => {
    if (props.hide) {
      setShowLeftMenu(false);
      setShowRightMenu(true);
    }
    if (index < userFnF?.length) {
      setActiveProfile((prevState) => ({
        id: index,
        action: "manage",
        user: userFnF[index],
      }));
      return;
    } else {
      setActiveProfile((prevState) => ({
        id: index,
        action: "invite",
        user: null,
      }));
      return;
    }
  };

  const saveChanges = async (profile) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/profile/friendsAndFamily`,
        "PUT",
        JSON.stringify({
          userObj: activeProfile.user,
          newName: profile.name,
          newColour: profile.colour,
        })
      );
    } catch (err) {}
    getProfile();
  };

  const removeUser = async (user) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/profile/friendsAndFamily`,
        "DELETE",
        JSON.stringify({
          name: user.name,
          status: user.status,
        })
      );
    } catch (err) {}
    getProfile();
    goBack();
  };

  const generateLink = async (name, colour) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/profile/friendsAndFamily`,
        "POST",
        JSON.stringify({
          name: name,
          colour: colour,
        })
      );
      if (responseData) {
        getProfile();
      }
    } catch (err) {}
  };

  const goBack = () => {
    if (props.hide) {
      setShowLeftMenu(true);
      setShowRightMenu(false);
    }
  };

  return (
    <div className="profile-container">
      {activeProfile && showLeftMenu && (
        <div className={`menu-left ${props.hide ? "simplify" : ""}`}>
          <InviteLeftMenu
            userFnF={userFnF}
            activeProfile={activeProfile}
            handleActiveProfile={handleActiveProfile}
          />
        </div>
      )}
      {activeProfile && showRightMenu && (
        <div className={`menu-right ${props.hide ? "simplify" : ""}`}>
          <ManageFnFRightMenu
            userFnF={userFnF}
            profile={activeProfile}
            saveChanges={saveChanges}
            removeUser={removeUser}
            generateLink={generateLink}
            hide={props.hide}
            goBack={goBack}
          />
        </div>
      )}
    </div>
  );
};

export default Invite;
