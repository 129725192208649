import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import TextArea from "../../../individualReport/pages/components/TextArea";
import CircleGraph from "../components/graphs/circleGraph/CircleGraph";
import CompanyStatistics from "./CompanyStatistics";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/pro-light-svg-icons";

function LiveAnalytics(props) {
  const { t } = useTranslation();
  const limit = props.threshold;
  const [dataActiveFilter, setDataActiveFilter] = useState(t("CS001"));
  const [dataFilterOpen, setDataFilterOpen] = useState(false);

  function toggleDataFilterOpen() {
    dataFilterOpen ? setDataFilterOpen(false) : setDataFilterOpen(true);
  }

  function dataFilterSelectHandler(event) {
    if (
      event.target.tagName === "svg" &&
      event.target.parentElement.title !== dataActiveFilter
    ) {
      setDataActiveFilter(event.target.parentElement.title);
    } else if (
      event.target.tagName === "DIV" &&
      event.target.title !== dataActiveFilter
    ) {
      setDataActiveFilter(event.target.title);
    }
    setDataFilterOpen(false);
  }

  function scrollToPos(elmnt, pos) {
    var section = document.body.offsetTop - 90;
    window.scroll({
      top: section,
      behavior: "smooth",
    });

    var items = document.getElementsByClassName("scrollLink");
    for (let i = 0; i < items.length; i++) {
      items[i].classList.remove("active");
      if (i === pos) {
        items[i].classList.add("active");
      }
    }
  }

  let engagement;
  if (
    props.coData.report_period_array.length > 0 &&
    props.coData.licences_array.length > 0
  ) {
    engagement = Math.round(
      (100 /
        Number(
          props.coData.licences_array[
            props.coData.report_period_array.indexOf(props.reportPeriod)
          ]
        )) *
        props.scores.completed_assessments
    );
  } else {
    engagement = Math.round(
      (100 / props.coData.licences) * props.scores.completed_assessments
    );
  }

  return (
    <React.Fragment>
      <div className="report-section active analytics">
        <div className="title_with_filters">
          <h2
            className={
              props.scores.completed_assessments < limit ||
              !props.coData.analytics_open ||
              props.coData.analytics_open !== "yes"
                ? "main_title no_nav"
                : "main_title"
            }
          >
            {t("LA001")}
          </h2>
          {props.coData.report_period && (
            <h3 className="period_title">
              {props.coData.report_period.length < 3
                ? t("LA002") + props.coData.report_period
                : props.coData.report_period}{" "}
              - {dataActiveFilter}
            </h3>
          )}
          {(props.scores.completed_assessments >= limit ||
            (props.scores.total < limit && props.filtersArr.length > 0)) &&
            props.coData.analytics_open === "yes" && (
              <CompanyStatistics
                threshold={props.threshold}
                onOpenModal={props.onOpenModal}
                data={props.scores}
                coData={props.coData}
                reportPeriod={props.reportPeriod}
                getDataForSetPeriod={props.getDataForSetPeriod}
                onFilterChange={props.onFilterChange}
                isLoading={props.isLoading}
                filtersArr={props.filtersArr}
                toggleFilterOpen={props.toggleFilterOpen}
                filterOpen={props.filterOpen}
                dataFilterSelectHandler={dataFilterSelectHandler}
                dataActiveFilter={dataActiveFilter}
                dataFilterOpen={dataFilterOpen}
                toggleDataFilterOpen={toggleDataFilterOpen}
              />
            )}
        </div>

        {(props.scores.completed_assessments < limit ||
          (props.scores.total < limit && props.filtersArr.length > 0) ||
          !props.coData.analytics_open ||
          props.coData.analytics_open !== "yes") && (
          <div id="health-report" className="results-content">
            <TextArea containerClass="clear half_square noreport"></TextArea>
            <TextArea containerClass="clear half_square">
              <CircleGraph
                title="Platform"
                colour="red"
                value={props.scores.completed_assessments}
                subText={t("LA011")}
                large
                percent={engagement}
                modalText={t("LA003")}
                onOpenModal={props.onOpenModal}
              />

              <span className="health_assessment_closed">
                {props.scores.total < limit && props.filtersArr.length > 0 ? (
                  <React.Fragment>
                    <h2>{t("LA004")}</h2>
                    <p>{t("LA005")}</p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <h2>{t("LA006")}</h2>
                    <p>
                      {t("LA007")}{" "}
                      <Link to="/company-report/engagement-resources">
                        {t("LA008")}
                      </Link>
                      .
                    </p>
                  </React.Fragment>
                )}

                <Link to="/company-report/engagement-resources">
                  <button className="green">
                    <FontAwesomeIcon icon={faArrowUp} className="" />
                    {t("LA009")}
                  </button>
                </Link>
                <button
                  className="black right"
                  onClick={() => {
                    props.onOpenModal();
                  }}
                >
                  {t("LA010")}
                </button>
              </span>
            </TextArea>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
export default LiveAnalytics;
