import React, { useState, useCallback, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./Style.css";

import Header from "../../shared/header/Header";
import SideMenu from "../menu/Menu";
import Modal from "../../shared/modal/Modal";
import AccessDenied from "../../shared/components/accessDenied/AccessDenied";
import CoRepFilter from "./components/CoRepFilter";

import LiveAnalytics from "./sections/LiveAnalytics";
import UsageStatistics from "./sections/UsageStatistics";
import Campaigns from "./sections/CampaignsComms";
import Engagement from "./sections/EngagementResources";
import HealthWellbeing from "./sections/HealthWellbeingStrategy";
import Leadership from "./sections/LeadershipLab";
import Partners from "./sections/PartnersProviders";

import ErvImport from "./sections/ErvImport";

import { AuthContext } from "../../shared/context/auth-context";
import { useAuth } from "../../shared/hooks/auth-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useMedia } from "../../shared/hooks/media-hook";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

function CompanyReport(props) {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const { tags } = useAuth();
  const {
    mediaModalIsOpen,
    mediaModalClickHandler,
    mediaContent,
    activeId,
    openMediaModal,
    favourites,
    addFavouriteHandler,
    progressList,
    addProgressHandler,
    scrolled,
  } = useMedia();
  const auth = useContext(AuthContext);

  const [threshold, setThreshold] = useState(15);
  const [scores, setScores] = useState(); //{completed_assessments: 20};
  const [coData, setCoData] = useState();
  const [usage, setUsage] = useState();
  const [reportPeriod, setReportPeriod] = useState();

  const [days, setDays] = useState(30);
  const [filtersArr, setFiltersArr] = useState([]);
  const [allowedFilters, setAllowedFilters] = useState();
  const [filterSnapshot, setFilterSnapshot] = useState();

  const getDataForSetPeriod = (period) => {
    // setFiltersArr([]);
    fetchData(period);
  };

  const fetchData = async (period) => {
    try {
      const formData = new FormData();
      formData.append("userid", auth.userId);
      formData.append("filtersArr", filtersArr);
      formData.append("periodid", period);
      formData.append("days", days);

      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/assessments/datarequest`,
        "POST",
        formData,
        {} //Keep to ensure it ensure doesn't send Content-Type
      );
      if (responseData) {
        if (responseData?.leadership_threshold) {
          setThreshold(responseData.leadership_threshold);
        }
        setScores(responseData.scores);
        // if (!period && !coData) {
        setCoData(responseData.codata);
        // }
        setUsage(responseData.usagedata);
        //check current report period if different or no filters
        // setFiltersArr(responseData.codata.applied_filters);
        if (
          (filtersArr.length < 1 && !allowedFilters) ||
          (filtersArr.length < 1 && !reportPeriod) ||
          (filtersArr.length < 1 &&
            reportPeriod &&
            reportPeriod !== responseData.report_period) ||
          responseData.codata.applied_filters.length < 1
        ) {
          setAllowedFilters(responseData.codata.allowed_filters);
        }
        setReportPeriod(responseData.codata.report_period);
      }
    } catch (err) {}
  };

  const pageid = useParams().pageid;
  const subpageid = useParams().subpageid;

  useEffect(() => {
    if (pageid !== "live-analytics" || !auth.userId) return;
    fetchData();
  }, [pageid, days, filtersArr, auth]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTextContent, setModalTextContent] = useState("");

  const modalClickHandler = useCallback(() => {
    setModalIsOpen(false);
  }, [modalIsOpen]);

  const openModal = useCallback(
    (props) => {
      if (!props) {
        props =
          "If engagement is low and you would like to extend your health assessment window, please contact one of the Champion team.";
      }
      setModalIsOpen(true);
      // if (typeof props === "object") {
      //   props = "Not currently available.";
      // }
      setModalTextContent(props);
    },
    [modalIsOpen, modalTextContent]
  );

  const filterChangeHandler = useCallback(
    (props) => {
      if (props.days) {
        setDays(props.days);
      } else if (props.filtersArr || props.filtersArr.length === 0) {
        setFiltersArr(props.filtersArr);
      }
    },
    [days, filtersArr]
  );

  window.onscroll = function () {
    var scrolled =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;
    if (document.getElementsByClassName("scrollLinks").length > 0) {
      if (scrolled > 160) {
        document
          .getElementsByClassName("scrollLinks")[0]
          .classList.add("scrolled");
      } else {
        document
          .getElementsByClassName("scrollLinks")[0]
          .classList.remove("scrolled");
      }
    }
  };

  useEffect(() => {
    if (scrolled) {
      window.scrollTo(0, scrolled);
    }
  }, [scrolled]);

  function onChangeSliderPos(props) {
    let current = sliderPos;
    current[props.id] = props.pos;
    setSliderPos(current);
  }

  const [sliderPos, setSliderPos] = useState({});

  //filter
  const [filterOpen, setFilterOpen] = useState(false);

  function toggleFilterOpen() {
    setFilterOpen(!filterOpen);
  }

  function filterSelectHandler(filtersArr, filterObj) {
    filterChangeHandler({ filtersArr: filtersArr });
    setFilterSnapshot(filterObj);
    if (filtersArr.length > 0) {
      setFilterOpen(false);
      window.scrollTo(0, 0);
    }
  }

  const clearFilterArr = () => {
    setFiltersArr([]);
  };

  return (
    <div id="main_container" className="reports company_report">
      {filterOpen && (
        <CoRepFilter
          threshold={threshold}
          coData={coData}
          filterSelectHandler={filterSelectHandler}
          getDataForSetPeriod={getDataForSetPeriod}
          toggleFilterOpen={toggleFilterOpen}
          filtersArr={filtersArr}
          allowedFilters={allowedFilters}
          filterSnapshot={filterSnapshot}
          clearFilterArr={clearFilterArr}
        />
      )}
      <Modal
        modalIsOpen={modalIsOpen}
        onModalClose={modalClickHandler}
        modalText={modalTextContent}
      />

      <Header page="company" />
      {parseInt(auth.access) > 2 && !auth.isHub ? (
        <React.Fragment>
          <div id="left_container">
            <SideMenu coData={coData} />
          </div>
          <div id="right_container">
            {pageid === "live-analytics" && !scores && (
              <LoadingSpinner asOverlay />
            )}

            {pageid === "live-analytics" && !subpageid && scores && coData && (
              <LiveAnalytics
                threshold={threshold}
                onOpenModal={openModal}
                scores={scores}
                onOpenMediaModal={openMediaModal}
                coData={coData}
                onFilterChange={filterChangeHandler}
                usage={usage}
                reportPeriod={reportPeriod}
                getDataForSetPeriod={getDataForSetPeriod}
                isLoading={isLoading}
                filtersArr={filtersArr}
                toggleFilterOpen={toggleFilterOpen}
                filterOpen={filterOpen}
              />
            )}
            {pageid === "usage-statistics" && !subpageid && (
              <UsageStatistics
                threshold={threshold}
                onOpenModal={openModal}
                data={scores}
                onOpenMediaModal={openMediaModal}
                coData={coData}
                onFilterChange={filterChangeHandler}
                usage={usage}
                reportPeriod={reportPeriod}
              />
            )}
            {pageid === "campaigns-communications" && !subpageid && (
              <Campaigns
                onOpenModal={openModal}
                scores={scores}
                onOpenMediaModal={openMediaModal}
              />
            )}
            {pageid === "engagement-resources" && !subpageid && (
              <Engagement
                onOpenModal={openModal}
                scores={scores}
                onOpenMediaModal={openMediaModal}
              />
            )}
            {pageid === "health-wellbeing-strategy" && !subpageid && (
              <HealthWellbeing
                onOpenModal={openModal}
                scores={scores}
                onOpenMediaModal={openMediaModal}
                coData={coData}
              />
            )}
            {pageid === "leadership-lab" && !subpageid && (
              <Leadership
                onOpenModal={openModal}
                scores={scores}
                onOpenMediaModal={openMediaModal}
                tags={tags}
                favourites={favourites}
                progressList={progressList}
                sliderPos={sliderPos}
                onChangeSliderPos={onChangeSliderPos}
                scrolled={scrolled}
              />
            )}
            {pageid === "partners-providers" && !subpageid && (
              <Partners
                onOpenModal={openModal}
                scores={scores}
                onOpenMediaModal={openMediaModal}
              />
            )}
            {pageid === "erv-import" && !subpageid && (
              <ErvImport coData={coData} />
            )}
          </div>
        </React.Fragment>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}
export default CompanyReport;
