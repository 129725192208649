import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import { useHttpClient } from "../../shared/hooks/http-hook";

const Passcode = (props) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { t } = useTranslation();

  const [code, setCode] = useState("");
  const [accepted, setAccepted] = useState(false);

  const handleValueChange = (e) => {
    const value = e.target.value;
    setCode(value);
    let valid = /^\d+$/.test(value);
    if ((valid && value.length < 5) || value.length < 1) setCode(value);
    if (valid && value.length === 4) {
      setAccepted(true);
    }
  };

  const handleNavigation = () => {
    navigate("/reset", {
      state: { passcode: true },
    });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    let el = document.getElementById("passcode");
    if (!props.confirm && code.length === 4) {
      props.handleContinue(code);
    } else if (props.confirm && props.passcode === code) {
      el.classList.add("confirmed");
      setTimeout(() => {
        props.handleContinue(code);
      }, 2000);
    } else {
      setErrorMessage(t("R054"));
      el.classList.add("wrong");

      //Only reset on signup screen
      if (props.handleReset) {
        setTimeout(() => {
          props.handleReset();
        }, 2000);
      }
    }
  };

  useEffect(() => {
    if (props.errorMessage && props.errorMessage.length > 0) {
      setErrorMessage(props.errorMessage);
      setTimeout(() => {
        setCode("");
      }, 2000);
    }
  }, [props.errorMessage]);

  return (
    <React.Fragment>
      <form>
        <div className="passcode" tabIndex={0}>
          <input
            type="password"
            className="code_input passcode"
            id="passcode"
            name="confirm_password"
            minLength="4"
            maxLength="4"
            placeholder={t("R072")}
            onChange={handleValueChange}
            value={code}
          />
        </div>
        {isLoading && <LoadingSpinner asOverlay />}
        {errorMessage && (
          <div className="error_container passcode">
            <p className="login_error">
              {" "}
              <FontAwesomeIcon icon={faTriangleExclamation} className="icon" />
              {errorMessage}
            </p>
          </div>
        )}
        {!isLoading && (
          <button
            onClick={handleContinue}
            id="continue"
            className={accepted ? "accepted" : "declined"}
          >
            {t("R013")}
          </button>
        )}
        <a
          style={{
            margin: "20px 0px 0px 0px",
            fontFamily: "futura-pt-book",
          }}
          className="signin"
          onClick={handleNavigation}
        >
          {t("R122")}
        </a>
      </form>
    </React.Fragment>
  );
};

export default Passcode;
