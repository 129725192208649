import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colours } from "../../../assets/colours";
import PinkButton1 from "../../../shared/components/styledComponents/pinkButton1/PinkButton1";
import smileyFace from "../../images/smileyFace.png";
import { faCheck, faShare, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { useEffect, useState } from "react";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import WhitePopUpCTA from "../../../shared/modal/whitePopUp/WhitePopUpCTA";
import WhitePopUpNoTitle from "../../../shared/modal/whitePopUp/WhitePopUpNoTitle";
import ChevronBackBtn from "../../../shared/components/styledComponents/chevronBackBtn/ChevronBackBtn";
import { useTranslation } from "react-i18next";

const colors = ["red", "orange", "yellow", "green", "blue"];

const ManageFnFRightMenu = (props) => {
  const { t } = useTranslation();
  const [profile, setProfile] = useState();
  const [inputName, setInputName] = useState();
  const [copied, setCopied] = useState(false);
  const [action, setAction] = useState(props.profile.action); // ["manage", "invite"]
  const [randomColour, setRandomColour] = useState();
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (field, value) => {
    let valid = false;
    if (field === "name") {
      const nameRegex = /^[a-zA-Z0-9\s.'-]*$/;
      valid = nameRegex.test(value);
      valid = value.length < 50 && valid;
    } else {
      valid = true;
    }
    if (valid) {
      setProfile((prevProfile) => ({
        ...prevProfile,
        [field]: value,
      }));
    }
  };

  useEffect(() => {
    if (props.profile.action === "manage") {
      setProfile(props.profile.user);
      setInputName(props.profile.user.name);
      setAction(props.profile.action);
    } else if (props.profile.action === "invite") {
      setAction(props.profile.action);
      setRandomColour(colors[Math.floor(Math.random() * 5)]);
      setProfile({ name: "", colour: "", status: "pending" });
    }
  }, [props.profile, props.userFnF]);

  const saveToClipboard = () => {
    const content = `<p>${t("S128")} ${profile.token}. ${t(
      "S128A"
    )}<a href="https://app.championhealth.co.uk/code/${
      profile.token
    }"  target="_blank" rel="noopener noreferrer">https://app.championhealth.co.uk/code/${
      profile.token
    }</a>.</p>`;

    const dummy = document.createElement("p");
    dummy.innerHTML = content;
    document.body.appendChild(dummy);

    const range = document.createRange();
    range.setStartBefore(dummy);
    range.setEndAfter(dummy);

    const selection = window.getSelection();
    // First clear, in case the user already selected some other text
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand("copy");
    document.body.removeChild(dummy);
    setCopied(true);
  };

  return (
    <div className="edit-field fs-exclude">
      {props.hide && (
        <div className="btn-cont ">
          <ChevronBackBtn onClick={() => props.goBack()} />
        </div>
      )}
      {openModal && (
        <WhitePopUpCTA
          popupContents={{
            title: t("S007"),
            textArr: [
              { text: t("S020"), className: "sub-heading" },
              {
                text: t("S021"),
                className: "",
              },
              {
                text: (
                  <>
                    {t("S022")}{" "}
                    <a
                      href="https://support.championhealth.co.uk/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("S023")}
                    </a>
                  </>
                ),
                className: "",
              },
            ],
            buttons: [
              {
                text: t("M050"),
                color: "dark",
                onClick: () => {
                  setOpenModal(false);
                },
              },
              {
                text: t("T016"),
                color: "pink",
                onClick: () => {
                  props.removeUser(profile);
                  setOpenModal(false);
                },
              },
            ],
          }}
        />
      )}
      {copied && (
        <WhitePopUpNoTitle
          popupContents={{
            title: t("S004"),
            text: [t("S129")],
          }}
          ctaBtn={t("T015")}
          handleSubmit={() => {
            setCopied(false);
          }}
        />
      )}
      {props.profile.action && (
        <>
          <div className="edit-title centre">
            {action === "invite" ? t("S004") : `${t("S016")} ${inputName}`}
          </div>
          <div className="cw">
            <div
              className="ff-cont"
              style={{
                backgroundColor: colours[profile?.colour || randomColour],
              }}
            >
              <img src={smileyFace} alt="smiley face" />
            </div>
            <div className="color-row">
              {colors.map((color) => (
                <div
                  key={color}
                  id={color}
                  className="circle-ff"
                  onClick={(e) => handleChange("colour", e.target.id)}
                >
                  {color === (profile?.colour || randomColour) && (
                    <div className="inner-crc"></div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="field-cont">
            <span className="field-descr">{t("R027")}</span>
            <input
              type="text"
              value={profile?.name || ""}
              onChange={(e) => handleChange("name", e.target.value)}
            ></input>
          </div>
          {profile?.token && (
            <div className="field-cont">
              <span className="field-descr">{t("S018")}</span>
              <div className="link" onClick={saveToClipboard}>
                app.championhealth.co.uk/code/{profile.token}
                <FontAwesomeIcon icon={faShare} className="icon" />
              </div>
            </div>
          )}

          {action === "invite" && !profile?.token && (
            <PinkButton1
              className="wide marginTop30"
              disabled={!profile?.name || profile?.name?.length < 1}
              onClick={() => {
                setInputName(profile.name);
                props.generateLink(
                  profile.name,
                  profile?.colour || randomColour
                );
              }}
            >
              {t("S014")}
            </PinkButton1>
          )}
          {(action === "manage" || (action === "invite" && profile?.token)) && (
            <PinkButton1
              className="wide marginTop30"
              onClick={() => {
                setInputName(profile.name);
                props.saveChanges(profile);
              }}
            >
              {t("S017")}
            </PinkButton1>
          )}
          {action === "manage" && (
            <div
              className="bold-sm-txt centre"
              onClick={() => setOpenModal(true)}
            >
              {" "}
              <FontAwesomeIcon icon={faTrashAlt} className="icon" />
              {t("S007")}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ManageFnFRightMenu;
