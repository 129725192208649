import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import TopThree from "../../components/graphs/topThree/TopThree";
import BarChart from "../../components/graphs/barChart/BarChart";
import Radar from "../../components/graphs/radarGraph/RadarGraph";
import {
  DISABLED_GRAPHS,
  getDisabledData,
  listLimit,
} from "../../../../shared/util/companyReport";

function MentalWellbeing({ data, coData, onOpenModal, graphLimit }) {
  const { t } = useTranslation();
  const {
    nervous,
    control_worrying,
    trouble_relaxing,
    worrying_too_much,
    restless,
    annoyed,
    afraid,
    little_interest,
    feeling_down,
    trouble_falling_asleep,
    feeling_tired,
    poor_appetite,
    feeling_bad,
    trouble_concentrating,
    slow,
    anxiety_level_none,
    anxiety_level_mild,
    anxiety_level_moderate,
    anxiety_level_severe,
    anxiety_level_severe_no,
    anxiety_level_moderate_no,
    total,
    depression_level_none,
    depression_level_mild,
    depression_level_moderate,
    depression_level_moderate_severe,
    depression_level_severe,
    depression_level_moderate_no,
    depression_level_moderate_severe_no,
    depression_level_severe_no,
    phq9_no,
    phq9,
    mh_diagnosis_current,
    mh_diagnosis_previous,
    mh_diagnosis_none,
    received_mental_support_currently,
    received_mental_support_previously,
    received_mental_support_never,
    current_mental_health_conditions,
    neurodivergent_no,
    neurodivergent,
    productivity_mental_health_no,
    productivity_mental_health,
  } = data;
  const { showMentalHealthList, showNeurodivergent, neurodivergentCounter } =
    coData;

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const anxiety_radar_labels = [
    t("MW001"),
    [t("MW002"), t("MW003")],
    t("MW004"),
    [t("MW005"), t("MW006")],
    t("MW007"),
    t("MW008"),
    t("MW009"),
  ];

  const anxiety_radar_values = [
    nervous,
    control_worrying,
    trouble_relaxing,
    worrying_too_much,
    restless,
    annoyed,
    afraid,
  ];

  const depression_radar_labels = [
    t("MW010"),
    t("MW011"),
    [t("MW012"), t("MW013")],
    t("MW014"),
    t("MW015"),
    [t("MW016"), t("MW017")],
    [t("MW018"), t("MW019")],
    t("MW020"),
  ];

  const depression_radar_values = [
    little_interest,
    feeling_down,
    trouble_falling_asleep,
    feeling_tired,
    poor_appetite,
    feeling_bad,
    trouble_concentrating,
    slow,
  ];

  const anxiety = [
    { date: t("MW021"), value: anxiety_level_none },
    { date: t("MW022"), value: anxiety_level_mild },
    {
      date: t("MW023"),
      value: anxiety_level_moderate,
    },
    { date: t("MW024"), value: anxiety_level_severe },
  ];

  const depression = [
    { date: t("MW025"), value: depression_level_none },
    { date: t("MW026"), value: depression_level_mild },
    {
      date: t("MW027"),
      value: depression_level_moderate,
    },
    {
      date: t("MW028"),
      value: depression_level_moderate_severe,
    },
    {
      date: t("MW029"),
      value: depression_level_severe,
    },
  ];

  const mental_diagnosis = [
    { date: t("MW030"), value: mh_diagnosis_current },
    { date: t("MW031"), value: mh_diagnosis_previous },
    { date: t("MW032"), value: mh_diagnosis_none },
  ];

  const mental_treatment = [
    {
      date: t("MW033"),
      value: received_mental_support_currently,
    },
    {
      date: t("MW034"),
      value: received_mental_support_previously,
    },
    {
      date: t("MW035"),
      value: received_mental_support_never,
    },
  ];

  return (
    <React.Fragment>
      <Radar
        title={t("MW036")}
        subTitle={t("MW037")}
        labels={anxiety_radar_labels}
        values={anxiety_radar_values}
        wide
        modalText={t("MW038")}
        onOpenModal={onOpenModal}
      />

      <div className="restricted_height">
        <BarChart
          title={t("MW039")}
          data={anxiety}
          unit="%"
          modalText={t("MW040")}
          onOpenModal={onOpenModal}
        />
        <CircleGraph
          title={t("MW041")}
          square
          colour="colour2"
          value={
            Number(anxiety_level_severe_no) + Number(anxiety_level_moderate_no)
          }
          large
          percent={Math.round(
            (100 / total) *
              (Number(anxiety_level_severe_no) +
                Number(anxiety_level_moderate_no))
          )}
          onOpenModal={onOpenModal}
        />
      </div>
      <Radar
        title={t("MW042")}
        subTitle={t("MW043")}
        labels={depression_radar_labels}
        values={depression_radar_values}
        wide
        modalText={t("MW044")}
        onOpenModal={onOpenModal}
      />
      <div className="restricted_height">
        <BarChart
          title={t("MW045")}
          data={depression}
          unit="%"
          modalText={t("MW046")}
          onOpenModal={onOpenModal}
        />
        <CircleGraph
          title={t("MW047")}
          square
          colour="colour3"
          value={
            depression_level_moderate_no +
            depression_level_moderate_severe_no +
            depression_level_severe_no
          }
          large
          percent={Math.round(
            (100 / total) *
              (depression_level_moderate_no +
                depression_level_moderate_severe_no +
                depression_level_severe_no)
          )}
          onOpenModal={onOpenModal}
        />
      </div>
      <CircleGraph
        title={t("MW048")}
        square
        colour="colour3"
        value={phq9_no}
        large
        percent={phq9}
        modalText={t("MW049")}
        onOpenModal={onOpenModal}
      />
      <BarChart
        title={t("MW050")}
        data={mental_diagnosis}
        unit="%"
        modalText={t("MW051")}
        onOpenModal={onOpenModal}
      />
      <TopThree
        title={t("MW052")}
        square
        preText={t("MW053")}
        modalText={t("MW054")}
        onOpenModal={onOpenModal}
        value={
          showMentalHealthList &&
          current_mental_health_conditions.length >= listLimit
            ? current_mental_health_conditions
            : getDisabledData(DISABLED_GRAPHS.MENTAL_CONDITIONS)
        }
        disabled={
          !showMentalHealthList ||
          current_mental_health_conditions.length < listLimit
        }
      />
      <BarChart
        title={t("MW055")}
        data={mental_treatment}
        unit="%"
        startZero
        modalText={t("MW056")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("MW059")}
        square
        colour="colour1"
        value={showNeurodivergent ? neurodivergent_no : 0}
        subTitle={
          neurodivergentCounter >= graphLimit
            ? `${t("BC012")} ${neurodivergentCounter} ${t("BC013")}`
            : ""
        }
        large
        percent={showNeurodivergent ? neurodivergent : 0}
        modalText={t("MW060")}
        onOpenModal={onOpenModal}
        disabled={!showNeurodivergent}
      />
      <CircleGraph
        title={t("MW057")}
        square
        colour="colour1"
        value={productivity_mental_health_no}
        subText=""
        large
        percent={productivity_mental_health}
        modalText={t("MW058")}
        onOpenModal={onOpenModal}
      />
    </React.Fragment>
  );
}
export default MentalWellbeing;
