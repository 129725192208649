import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import TopThreeGraph from "../../components/graphs/topThreeGraph/TopThreeGraph";
import BarChart from "../../components/graphs/barChart/BarChart";
import {
  DISABLED_GRAPHS,
  getDisabledData,
  listLimit,
} from "../../../../shared/util/companyReport";

function MSKHealth({ data, onOpenModal, graphLimit }) {
  const { t } = useTranslation();
  const {
    pain,
    pain_no,
    no_pain,
    mild_pain,
    moderate_pain,
    severe_pain,
    seen_specialist_yes,
    seen_specialist_no,
    areas_of_pain,
    chronic_pain,
    chronic_pain_no,
    msk_productivity,
    msk_productivity_no,
    minimal_pain,
    moderate_severe_pain,
  } = data;

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const pain_data = [
    { date: t("MSKH001"), value: pain },
    { date: t("MSKH002"), value: no_pain },
  ];

  const pain_severity = [
    { date: t("MSKH025"), value: minimal_pain },
    { date: t("MSKH003"), value: mild_pain },
    { date: t("MSKH004"), value: moderate_pain },
    { date: t("MSKH026"), value: moderate_severe_pain },
    { date: t("MSKH005"), value: severe_pain },
  ];

  const treatment = [
    { date: t("MSKH006"), value: seen_specialist_yes },
    { date: t("MSKH007"), value: seen_specialist_no },
  ];

  return (
    <React.Fragment>
      <BarChart
        title={t("MSKH008")}
        data={pain_data}
        unit="%"
        modalText={t("MSKH009")}
        onOpenModal={onOpenModal}
      />
      <TopThreeGraph
        title={t("MSKH010")}
        square
        preText={t("MSKH011")}
        modalText={t("MSKH012")}
        onOpenModal={onOpenModal}
        data={
          areas_of_pain.length < listLimit || pain_no < graphLimit
            ? getDisabledData(DISABLED_GRAPHS.AREA_OF_PAIN)
            : areas_of_pain
        }
        disabled={areas_of_pain.length < listLimit || pain_no < graphLimit}
      />
      <CircleGraph
        title={t("MSKH013")}
        subTitle={t("MSKH014")}
        square
        colour="colour1"
        value={pain_no < graphLimit ? 0 : chronic_pain_no}
        large
        percent={pain_no < graphLimit ? 50 : chronic_pain}
        modalText={t("MSKH015")}
        onOpenModal={onOpenModal}
        disabled={pain_no < graphLimit}
      />
      <BarChart
        title={t("MSKH016")}
        data={
          pain_no < graphLimit
            ? getDisabledData(DISABLED_GRAPHS.PAIN_SEVERITY)
            : pain_severity
        }
        unit="%"
        modalText={t("MSKH017")}
        onOpenModal={onOpenModal}
        disabled={pain_no < graphLimit}
      />
      <BarChart
        title={t("MSKH018")}
        data={
          pain_no < graphLimit
            ? getDisabledData(DISABLED_GRAPHS.TREATMENT)
            : treatment
        }
        unit="%"
        modalText={t("MSKH019")}
        onOpenModal={onOpenModal}
        disabled={pain_no < graphLimit}
      />
      <CircleGraph
        title={t("MSKH020")}
        subTitle=""
        square
        colour="colour2"
        value={msk_productivity_no}
        large
        percent={msk_productivity}
        modalText={t("MSKH021")}
        onOpenModal={onOpenModal}
      />
    </React.Fragment>
  );
}
export default MSKHealth;
