import { useCallback, useEffect, useState } from "react";
import PinkButton1 from "../../../shared/components/styledComponents/pinkButton1/PinkButton1";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import GreenCheckOverlay from "../../../shared/modal/greenCheckOverlay/GreenCheckOverlay";
import ChevronBackBtn from "../../../shared/components/styledComponents/chevronBackBtn/ChevronBackBtn";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ChangePasscodeRightMenu = (props) => {
  const navigate = useNavigate();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { t } = useTranslation();
  const [passcodeState, setPasscodeState] = useState({
    currentPasscode: "",
    newPasscode: "",
    confirmNewPasscode: "",
    validPasscode: false,
    passcodesMatch: false,
    changeFailed: false,
  });

  const [disabled, setDisabled] = useState(true);
  const [showGreenCheck, setShowGreenCheck] = useState(false);

  const showGreenCheckOverlay = () => {
    setShowGreenCheck(true);
    setTimeout(() => {
      setShowGreenCheck(false);
    }, 2000);
  };

  const handlePasscodeChange = useCallback((event) => {
    const { name, value } = event.target;
    setPasscodeState((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: value,
      };

      if (name === "newPasscode") {
        updatedState.validPasscode = /^\d{4}$/.test(value);
        updatedState.passcodesMatch = value === prevState.confirmNewPasscode;
      } else if (name === "confirmNewPasscode") {
        updatedState.passcodesMatch = value === prevState.newPasscode;
      } else if (name === "currentPasscode" && prevState.changeFailed) {
        updatedState.changeFailed = false;
      }

      return updatedState;
    });
  }, []);

  const handleChangePasscode = async () => {
    if (!disabled) {
      clearError();
      let responseData;
      try {
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/profile/credentials`,
          "POST",
          JSON.stringify({
            currentCode: passcodeState.currentPasscode,
            newCode: passcodeState.newPasscode,
            type: "passcode",
          })
        );

        if (responseData) {
          showGreenCheckOverlay();
        }
      } catch (err) {
        console.log(err);
        setPasscodeState((prevState) => ({ ...prevState, changeFailed: true }));
      }
    }
  };

  useEffect(() => {
    if (
      passcodeState.currentPasscode.length === 4 &&
      passcodeState.newPasscode.length === 4 &&
      passcodeState.confirmNewPasscode.length === 4 &&
      passcodeState.validPasscode &&
      passcodeState.passcodesMatch
    ) {
      setDisabled(false);
    }
  }, [
    passcodeState.currentPasscode,
    passcodeState.newPasscode,
    passcodeState.confirmNewPasscode,
    passcodeState.validPasscode,
    passcodeState.passcodesMatch,
  ]);

  const handleNavigation = () => {
    navigate("/reset", {
      state: { passcode: true },
    });
  };

  const renderPasscodeField = useCallback(
    (fieldName, fieldDescription, showPasscode, toggleShowPasscode) => {
      let errorCondition = false;
      let errorMessage = "";
      if (fieldName === "currentPasscode" && passcodeState.changeFailed) {
        errorCondition = true;
        errorMessage = (
          <>
            {t("S066")}
            <a onClick={handleNavigation}> {t("S060")}</a>
          </>
        );
      } else if (
        fieldName === "newPasscode" &&
        passcodeState.newPasscode.length > 0 &&
        !passcodeState.validPasscode
      ) {
        errorCondition = true;
        errorMessage = t("S067");
      } else if (
        fieldName === "confirmNewPasscode" &&
        passcodeState.confirmNewPasscode.length > 0 &&
        !passcodeState.passcodesMatch
      ) {
        errorCondition = true;
        errorMessage = t("S068");
      }
      return (
        <>
          <div className={`field-cont ${errorCondition ? "error" : ""}`}>
            <span className="field-descr">{fieldDescription}</span>
            <input
              type="password"
              name={fieldName}
              value={passcodeState[fieldName]}
              onChange={handlePasscodeChange}
            ></input>
          </div>
          {errorCondition && <div className="error">{errorMessage}</div>}
        </>
      );
    },
    [passcodeState, handlePasscodeChange]
  );

  return (
    <>
      {props.profile && (
        <>
          {showGreenCheck && <GreenCheckOverlay />}
          <div className="edit-field fs-exclude">
            {props.hide && (
              <div className="btn-cont ">
                <ChevronBackBtn onClick={() => props.changeMenu(1, "back")} />
              </div>
            )}
            <div className="edit-title">{t("S028")}</div>
            <p className="cm profile">{t("S038")}</p>
            {renderPasscodeField("currentPasscode", t("S039"))}
            {renderPasscodeField("newPasscode", t("S040"))}
            {renderPasscodeField("confirmNewPasscode", t("S041"))}
            <div className="bold-sm-txt cm">
              {" "}
              <a href="/reset"> {t("S121")}</a>
            </div>

            <PinkButton1
              className="wide marginTop30"
              onClick={handleChangePasscode}
            >
              {t("S028")}
            </PinkButton1>
          </div>
        </>
      )}
    </>
  );
};

export default ChangePasscodeRightMenu;
