import "./style.css";
import React, { useEffect, useCallback, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

import Logo from "../../pages/images/logo_white.svg";
import Layout from "../components/Layout";

import Modal from "../../shared/modal/Modal";
import SignupScreen1 from "./SignupScreen1";
import SignupScreen2 from "./SignupScreen2";
import SignupScreen3 from "./SignupScreen3";
import SignupScreen4 from "./SignupScreen4";
import SignupScreen5 from "./SignupScreen5";

const Signup = (props) => {
  const { error, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  const [userId, setUserId] = useState();
  const [clientId, setClientId] = useState();
  const [activeScreen, setActiveScreen] = useState();
  const [departments, setDepartments] = useState();
  const [subDepartments, setSubDepartments] = useState();
  const [regions, setRegions] = useState();
  const [image, setImage] = useState();
  const [erv, setErv] = useState(false);
  const [ervFieldName, setErvFieldName] = useState("");
  const [fandf, setFandf] = useState(false);
  const [isStandardAuthActive, setIsStandardAuthActive] = useState(false);
  const [isMicrosoftAuthActive, setIsMicrosoftAuthActive] = useState(false);
  const [isGoogleAuthActive, setIsGoogleAuthActive] = useState(false);
  const [singleSignOn, setSingleSignOn] = useState([]);
  const [domainValidation, setDomainValidation] = useState(false);
  const [passcode, setPasscode] = useState("");
  const [fandFToken, setFandFToken] = useState();
  const [profile, setProfile] = useState();
  const [name, setName] = useState();
  const [isHub, setIsHub] = useState(false);
  const [tempAccessToken, setTempAccessToken] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let storedClientData = JSON.parse(localStorage.getItem("userData"));

    if (!auth.isLoggedIn && !location.state) {
      //Return to company code page
      if (!storedClientData) {
        navigate("/");
        return;
      }

      //Retrieve stored data from local storage
      if (!clientId) {
        setClientId(storedClientData.clientid);
      }
      if (storedClientData.fandfUser) {
        setFandf(true);
        setFandFToken(storedClientData.fandfUser);
      }
      if (storedClientData.domain_validation) {
        setDomainValidation(true);
      }
      setIsStandardAuthActive(storedClientData.isStandardAuthActive);
      setIsMicrosoftAuthActive(storedClientData.isMicrosoftAuthActive);
      setIsGoogleAuthActive(storedClientData.isGoogleAuthActive);
      setSingleSignOn(storedClientData.singleSignOn);
      setActiveScreen(1);
    } else if (location.state && location.state.user?._id) {
      const {
        user,
        dept,
        sub_depts,
        regions,
        profilePic,
        erv,
        erv_field_name,
        tempToken,
        hub,
      } = location.state;

      //Handle user data from company code or sign in page
      setUserId(user._id);
      setName(user.name);

      setFandf(user.access === "2");
      if (hub) {
        setIsHub(true);
      }

      if (
        user._id &&
        user.name &&
        !user.passcode &&
        !user.completed_registration &&
        hub &&
        user.access === "4"
      ) {
        // Send hub user lvl4 to accept T&C and set passcode
        setProfile(user);
        setTempAccessToken(tempToken);
        setActiveScreen(3);
      } else if (
        user._id &&
        user.name &&
        !user.passcode &&
        (user.auth_type === undefined || user.auth_type === "ChampionHealth")
      ) {
        // Send user to set passcode screen
        setTempAccessToken(tempToken);
        setActiveScreen(4);
      } else if (
        (user._id && !user.name) ||
        user.completed_registration !== undefined ||
        user.completed_registration !== true
      ) {
        // Send user to complete their profile screen
        setDepartments(
          dept?.map((value, index) => ({ id: index + 1, title: value }))
        );
        setSubDepartments(sub_depts);
        setRegions(regions);
        setImage(profilePic);
        setErv(erv);
        setErvFieldName(erv_field_name);
        setActiveScreen(2);
      }
    }
  }, [auth.isLoggedIn, clientId, location, navigate, props]);

  const registerUser = async (dataObj) => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/signup`,
        "POST",
        JSON.stringify({
          email: dataObj.email.toLowerCase(),
          password: dataObj.password,
          fandfUser: fandFToken,
          clientId: clientId,
        })
      );
      handleRegisterUserResponse(response);
    } catch (err) {}
  };

  const handleRegisterUserResponse = async (response) => {
    if (response) {
      if (response.user.name !== "" && response.user.name !== null) {
        setName(response.user.name);
      }
      if (response?.hub) {
        setIsHub(true);
      }
      setUserId(response.user._id);
      setDepartments(
        response.dept?.map((value, index) => ({ id: index + 1, title: value }))
      );
      setSubDepartments(response.sub_depts);
      setRegions(response.regions);
      setImage(response.profilePic);
      setErv(response.erv);
      setErvFieldName(response.erv_field_name);
      setActiveScreen(2);
    }
  };

  const handleEIdcheck = async (eid) => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/checkEId`,
        "POST",
        JSON.stringify({
          userid: userId,
          eid: eid,
        })
      );
      if (response && response.isValidEId === true) {
        setActiveScreen(3);
      }
    } catch (err) {}
  };

  const createProfile = async (profile) => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/createProfile`,
        "POST",
        JSON.stringify({
          userId: userId,
          name: profile.name,
          dept: profile.departmentid,
          sub_dept: profile.subDepartmentid,
          regionid: profile.regionid,
          image: image,
          eid: profile.eid,
          platform: "web",
        })
      );

      if (response) {
        if (response.token && response.user && response.clientName) {
          handleAuthenticatedUser(response);
        } else {
          setTempAccessToken(response.tempToken);
          setActiveScreen(4);
        }
      }
    } catch (err) {}
  };

  const createPasscode = async (passcode) => {
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/setPasscode`,
        "POST",
        JSON.stringify({
          tempToken: tempAccessToken,
          passcode: passcode,
          platform: "web",
        })
      );
      handleAuthenticatedUser(response);
    } catch (err) {}
  };

  const handleAuthenticatedUser = async (response) => {
    if (response && response.user) {
      auth.login(
        response.user._id,
        response.token,
        response.user.name,
        response?.token?.expirationDate,
        response.user.access,
        response.user.image,
        response.user.tags,
        response.clientName,
        response.user.nps_status,
        response.user.auth_type,
        response.user.email,
        response.user.language,
        response?.hub
      );
      if (response.user.tags.length > 0) {
        navigate("/for-you");
      } else {
        navigate("/assessment");
      }
    }
  };

  const handleContinue = (dataObj) => {
    if (!dataObj?.progress) {
      setActiveScreen(1);
    } else if (dataObj.progress === "screen1") {
      registerUser(dataObj);
    } else if (dataObj.progress === "screen2") {
      setProfile(dataObj);
      if (erv === "yes" && !fandf && !isHub) {
        handleEIdcheck(dataObj.eid);
      } else {
        setActiveScreen(3);
      }
    } else if (dataObj.progress === "screen3" && dataObj.accepted) {
      createProfile(profile);
    } else if (dataObj.progress === "screen4") {
      setPasscode(dataObj.code);
      setActiveScreen(5);
    } else if (dataObj.progress === "screen5" && dataObj.code === passcode) {
      createPasscode(passcode);
    } else if (dataObj.progress === "screen5" && dataObj.code === "reset") {
      setActiveScreen(4);
    }
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTextContent, setModalTextContent] = useState("");

  const modalClickHandler = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const openModal = useCallback((props) => {
    setModalIsOpen(true);
    setModalTextContent(props);
  }, []);

  return (
    <Layout>
      <Modal
        modalIsOpen={modalIsOpen}
        onModalClose={modalClickHandler}
        modalText={modalTextContent}
      />
      <div id="right_container">
        <div className="frame">
          <div className="logo">
            <img
              src={Logo}
              className="icon title_icon"
              alt="Champion Health Logo"
            />
          </div>
          {auth.isLoggedIn && <p>{t("R046")}</p>}
          {activeScreen === 1 && (
            <SignupScreen1
              domain_validation={domainValidation}
              fandfUser={fandFToken}
              clientid={clientId}
              handleResponseSSO={handleRegisterUserResponse}
              isStandardAuthActive={isStandardAuthActive}
              isMicrosoftAuthActive={isMicrosoftAuthActive}
              isGoogleAuthActive={isGoogleAuthActive}
              singleSignOn={singleSignOn}
              handleContinue={handleContinue}
              error={error}
            />
          )}
          {activeScreen === 2 && (
            <SignupScreen2
              erv={erv}
              name={name}
              ervFieldName={ervFieldName}
              departments={departments}
              subDepartments={subDepartments}
              regions={regions}
              fandf={fandf}
              handleContinue={handleContinue}
              onOpenModal={openModal}
              error={error}
              isHub={isHub}
            />
          )}
          {activeScreen === 3 && (
            <SignupScreen3 handleContinue={handleContinue} />
          )}
          {activeScreen === 4 && (
            <SignupScreen4 handleContinue={handleContinue} />
          )}
          {activeScreen === 5 && (
            <SignupScreen5
              passcode={passcode}
              handleContinue={handleContinue}
            />
          )}
        </div>
        <div className="lower_container bottom_padding">
          {isStandardAuthActive && activeScreen === 1 && (
            <p className="signin">
              {t("R012")} <a href="/sign-in">{t("R011")}</a>
            </p>
          )}
          <p className="signin">
            {t("R075")}{" "}
            <a
              href="https://support.championhealth.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              {t("R076")}
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Signup;
