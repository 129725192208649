import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CircleGraph from "../../components/graphs/circleGraph/CircleGraph";
import BarChart from "../../components/graphs/barChart/BarChart";
import TopSixGraph from "../../components/graphs/topSixGraph/TopSixGraph";
import {
  DISABLED_GRAPHS,
  getDisabledData,
  listLimit,
} from "../../../../shared/util/companyReport";

function Energy({ data, coData, onOpenModal, graphLimit }) {
  const { t } = useTranslation();
  const {
    energy_calc_2,
    energy_calc_4,
    energy_calc_6,
    energy_calc_8,
    energy_calc_10,
    productivity_2,
    productivity_4,
    productivity_6,
    productivity_8,
    productivity_10,
    supported_yes,
    supported_sometimes,
    supported_no,
    team_yes,
    team_sometimes,
    team_no,
    supported_work_always,
    supported_work_often,
    supported_work_sometimes,
    supported_work_rarely,
    supported_work_never,
    part_of_team_always,
    part_of_team_often,
    part_of_team_sometimes,
    part_of_team_rarely,
    part_of_team_never,
    respected_work_always,
    respected_work_often,
    respected_work_sometimes,
    respected_work_rarely,
    respected_work_never,
    trusted_work_always,
    trusted_work_often,
    trusted_work_sometimes,
    trusted_work_rarely,
    trusted_work_never,
    listened_work_always,
    listened_work_often,
    listened_work_sometimes,
    listened_work_rarely,
    listened_work_never,
    negative_productivity,
    no_negative_productivity_no,
    average_productivity,
    hours_worked_average,
    no_negative_productivity,
    least_energetic,
    most_energetic,
  } = data;

  const {
    showNewSupport,
    showNewPartOfTeam,
    showLegacySupport,
    showLegacyPartOfTeam,
    newSupportedAtWorkCount,
    newPartOfTeamCount,
    respectedWorkCounter,
    showRespectedWork,
    trustedWorkCounter,
    showTrustedWork,
    showListenedWork,
    listenedWorkCounter,
  } = coData;

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const energy_levels = [
    { date: "1-2", value: energy_calc_2 },
    { date: "3-4", value: energy_calc_4 },
    { date: "5-6", value: energy_calc_6 },
    { date: "7-8", value: energy_calc_8 },
    { date: "9-10", value: energy_calc_10 },
  ];
  const productivity_levels = [
    { date: "< 2", value: productivity_2 },
    { date: "2-4", value: productivity_4 },
    { date: "5-6", value: productivity_6 },
    { date: "7-8", value: productivity_8 },
    { date: "9-10", value: productivity_10 },
  ];

  const feeling_supported = [
    { date: t("STR010"), value: supported_yes },
    {
      date: t("STR011"),
      value: supported_sometimes,
    },
    { date: t("STR012"), value: supported_no },
  ];

  const part_of_team = [
    { date: t("STR010"), value: team_yes },
    { date: t("STR011"), value: team_sometimes },
    { date: t("STR012"), value: team_no },
  ];

  const feeling_supported_at_work = [
    {
      date: t("STR036"),
      value: supported_work_always,
    },
    {
      date: t("STR035"),
      value: supported_work_often,
    },
    {
      date: t("STR034"),
      value: supported_work_sometimes,
    },
    {
      date: t("STR033"),
      value: supported_work_rarely,
    },
    {
      date: t("STR032"),
      value: supported_work_never,
    },
  ];

  const part_of_team_at_work = [
    {
      date: t("STR036"),
      value: part_of_team_always,
    },
    {
      date: t("STR035"),
      value: part_of_team_often,
    },
    {
      date: t("STR034"),
      value: part_of_team_sometimes,
    },
    {
      date: t("STR033"),
      value: part_of_team_rarely,
    },
    {
      date: t("STR032"),
      value: part_of_team_never,
    },
  ];

  const respected_at_work = [
    {
      date: t("STR036"),
      value: respected_work_always,
    },
    {
      date: t("STR035"),
      value: respected_work_often,
    },
    {
      date: t("STR034"),
      value: respected_work_sometimes,
    },
    {
      date: t("STR033"),
      value: respected_work_rarely,
    },
    {
      date: t("STR032"),
      value: respected_work_never,
    },
  ];

  const trusted_at_work = [
    {
      date: t("STR036"),
      value: trusted_work_always,
    },
    {
      date: t("STR035"),
      value: trusted_work_often,
    },
    {
      date: t("STR034"),
      value: trusted_work_sometimes,
    },
    {
      date: t("STR033"),
      value: trusted_work_rarely,
    },
    {
      date: t("STR032"),
      value: trusted_work_never,
    },
  ];

  const listened_at_work = [
    {
      date: t("STR036"),
      value: listened_work_always,
    },
    {
      date: t("STR035"),
      value: listened_work_often,
    },
    {
      date: t("STR034"),
      value: listened_work_sometimes,
    },
    {
      date: t("STR033"),
      value: listened_work_rarely,
    },
    {
      date: t("STR032"),
      value: listened_work_never,
    },
  ];

  let mprod = most_energetic.split(".");
  const most_energetic_value =
    mprod[0] +
    ":" +
    Math.round((mprod[1] / 100) * 60)
      .toString()
      .padStart(2, "0");
  let lprod = least_energetic.split(".");
  const least_energetic_value =
    lprod[0] +
    ":" +
    Math.round((lprod[1] / 100) * 60)
      .toString()
      .padStart(2, "0");

  return (
    <React.Fragment>
      <BarChart
        title={t("EG001")}
        data={energy_levels}
        unit="%"
        modalText={t("EG002")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("EG003")}
        subTitle={t("EG004")}
        square
        value={most_energetic_value}
        large
        modalText={t("EG005")}
        onOpenModal={onOpenModal}
      />
      <CircleGraph
        title={t("EG006")}
        subTitle={t("EG007")}
        square
        value={least_energetic_value}
        large
        modalText={t("EG008")}
        onOpenModal={onOpenModal}
      />
      <TopSixGraph
        title={t("EG009")}
        preText={t("EG010")}
        square
        modalText={t("EG011")}
        onOpenModal={onOpenModal}
        data={
          negative_productivity.length < listLimit
            ? getDisabledData(DISABLED_GRAPHS.NEGATIVE_PRODUCTIVITY)
            : negative_productivity
        }
        disabled={negative_productivity.length < listLimit}
      />
      <CircleGraph
        title={t("EG012")}
        square
        colour="colour2"
        value={no_negative_productivity_no}
        subText=""
        large
        percent={no_negative_productivity}
      />
      <CircleGraph
        title={t("EG013")}
        square
        colour="colour3"
        value={average_productivity}
        subText={t("EG014")}
        large
        modalText={t("EG015")}
        onOpenModal={onOpenModal}
      />
      <BarChart
        title={t("EG016")}
        subTitle={t("EG017")}
        data={productivity_levels}
        unit="%"
        width="wide"
        startZero
        modalText={t("EG018")}
        onOpenModal={onOpenModal}
      />
      <BarChart
        title={t("STR030")}
        data={
          showNewSupport
            ? feeling_supported_at_work
            : getDisabledData(DISABLED_GRAPHS.ENERGY_DISABLED)
        }
        subTitle={
          newSupportedAtWorkCount >= graphLimit
            ? `${t("BC012")} ${newSupportedAtWorkCount} ${t("BC013")}`
            : ""
        }
        unit="%"
        startZero
        modalText={t("STR038")}
        onOpenModal={onOpenModal}
        disabled={!showNewSupport}
      />
      <BarChart
        title={t("STR031")}
        data={
          showNewPartOfTeam
            ? part_of_team_at_work
            : getDisabledData(DISABLED_GRAPHS.ENERGY_DISABLED)
        }
        subTitle={
          newPartOfTeamCount >= graphLimit
            ? `${t("BC012")} ${newPartOfTeamCount} ${t("BC013")}`
            : ""
        }
        unit="%"
        startZero
        modalText={t("STR039")}
        onOpenModal={onOpenModal}
        disabled={!showNewPartOfTeam}
      />
      <BarChart
        title={t("STR041")}
        data={
          showRespectedWork
            ? respected_at_work
            : getDisabledData(DISABLED_GRAPHS.ENERGY_DISABLED)
        }
        subTitle={
          respectedWorkCounter >= graphLimit
            ? `${t("BC012")} ${respectedWorkCounter} ${t("BC013")}`
            : ""
        }
        unit="%"
        startZero
        modalText={t("STR042")}
        onOpenModal={onOpenModal}
        disabled={!showRespectedWork}
      />
      <BarChart
        title={t("STR043")}
        data={
          showTrustedWork
            ? trusted_at_work
            : getDisabledData(DISABLED_GRAPHS.ENERGY_DISABLED)
        }
        subTitle={
          trustedWorkCounter >= graphLimit
            ? `${t("BC012")} ${trustedWorkCounter} ${t("BC013")}`
            : ""
        }
        unit="%"
        startZero
        modalText={t("STR044")}
        onOpenModal={onOpenModal}
        disabled={!showTrustedWork}
      />
      <BarChart
        title={t("STR045")}
        data={
          showListenedWork
            ? listened_at_work
            : getDisabledData(DISABLED_GRAPHS.ENERGY_DISABLED)
        }
        subTitle={
          listenedWorkCounter >= graphLimit
            ? `${t("BC012")} ${listenedWorkCounter} ${t("BC013")}`
            : ""
        }
        unit="%"
        startZero
        modalText={t("STR046")}
        onOpenModal={onOpenModal}
        disabled={!showListenedWork}
      />
      <CircleGraph
        title={t("STR047")}
        square
        colour="colour3"
        value={hours_worked_average}
        subTitle={t("STR048")}
        large
        modalText={t("STR049")}
        onOpenModal={onOpenModal}
      />
      {showLegacySupport && (
        <BarChart
          title={t("STR024")}
          subTitle={t("STR037")}
          data={feeling_supported}
          unit="%"
          width="wide"
          startZero
          modalText={t("STR025")}
          onOpenModal={onOpenModal}
        />
      )}
      {showLegacyPartOfTeam && (
        <BarChart
          title={t("STR026")}
          subTitle={t("STR037")}
          data={part_of_team}
          unit="%"
          startZero
          modalText={t("STR027")}
          onOpenModal={onOpenModal}
        />
      )}
    </React.Fragment>
  );
}
export default Energy;
